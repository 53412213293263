/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.react-datepicker-popper {
  z-index: 10 !important;
}
.react-datepicker__input-container {
  padding-right: 30px;
}

.date-picker-custom {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 3.4em;
  padding-left: 13px;
  padding-right: 15px;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
}
.date-picker-custom:focus {
  outline: 0;
  border-color: #00ab55;
  border-width: 1px;
}
p.datepicker-legend {
  position: absolute;
  top: 0;
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.3);
  background: #fff;
  margin-top: -10px;
  padding: 1px 5px;
  margin-left: 7px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #00ab55 !important;
  color: #fff;
}
.add-stop-btn-loads {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 22px;
  gap: 8px;
  width: 182px;
  height: 48px;
  background: #00ab55;
  border-radius: 8px;
  flex: none;
  order: 5;
  flex-grow: 0;
  border: none;
  cursor: pointer;
  color: #fff;
}
.detailsOuter {
  display: flex;
}
.detailsOuter .detailsLeft {
  max-width: 100%;
}
.detailsOuter .detailsLeft.shrink {
  width: 60%;
}
.detailsOuter .detailsRight {
  display: none;
}
.detailsOuter .detailsRight.active {
  display: block;
  position: sticky;
  top: 0;
  height: 1000px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1111;
  border-left: 1px solid #d7d7d7;
}
/* .detailsOuter .detailsRight.active iframe { */
/* height: 800px; */
/* } */

.detailsOuter .detailsLeft.shrink .topFixCard {
  width: calc(100% - 915px);
}
.detailsOuter .detailsLeft input {
  font-size: 13px;
}

@media (max-width: 1199px) {
  .detailsOuter .detailsLeft.shrink .topFixCard {
    width: calc(100% - 60px);
    left: 40px;
  }
}
