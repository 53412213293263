/* for selected toggle-button-group */
.css-1iwloef-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: #ffff !important;
  background-color: #00ab55 !important;
  /* font-family: 'Public Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.css-bomy07-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  color: #00ab55;
  /* font-family: 'Public Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
/* end */
/* for load form label */
.load-form-label {
  margin-right: 14px;
}
.topFixCard {
  position: fixed;
  z-index: 111;
  width: calc(100% - 360px);
  left: 330px;
  top: 92px;
}
@media (max-width: 1199px) {
  .topFixCard {
    width: calc(100% - 60px);
    left: 40px;
  }
}
/* end */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.fullWidth {
  width: 100%;
}
